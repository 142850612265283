import { useEffect, useState } from "react";
import { Desease } from "./Desease";
import { Modal } from "./Modal";
import { AddForm } from "./AddForm";
import { useTable } from "../hook/useTable";

const Line = ({ data,categoryId,sapp}) => {
  const [deseaseData, setDeseaseData] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isListOpen, setIsListOpen] = useState(false);

  const {table,setTable} = useTable();
  
  const handleOpenModal = () => {
    setDeseaseData(null);
    setIsModalOpen(true);
  };

  const handleOpenList = () => {
    setIsListOpen(true);
  }
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCloseList = () => {
    setIsListOpen(false);
  };

  useEffect(() => {
    if (isModalOpen || isListOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isModalOpen,isListOpen]);

  const openEdit = value => {
    setDeseaseData(value);
    setIsModalOpen(true);
  }

  const delItem = selectedIndex =>{
    const newTable = table.map(item => 
      item.disease.map((disease, index) => {
        if (disease.id === data.id) {
          return {
            ...disease,
            selected: disease.selected.filter((_, i) => i !== selectedIndex)
          };
        }
        return disease;
      })
    )
    setTable(newTable);
  }

  const addItem = newItem =>{
    const newTable = table.map(item => 
      ({
        ...item,
        disease: item.disease.map(disease => {
          if (disease.id === data.id) {
            return {
              ...disease,
              selected: [...disease.selected, newItem]
            };
          }
          return disease;
        })
      })
    )
    setTable(newTable);
    setIsModalOpen(false);
  }

  const saveItem = (selectedIndex,newData) =>{
  
    const newTable = table.map(item => 
      ({
        ...item,
        disease: item.disease.map(disease => {
          if (disease.id === data.id) {
            // Оновлюємо об'єкт в масиві selected
            return {
              ...disease,
              selected: disease.selected.map((selected, index) => {
                if (index === selectedIndex) {
                  return { ...selected, ...newData }; // Оновлюємо дані
                }
                return selected; // Повертаємо незмінений об'єкт
              })
            };
          }
          return disease; // Повертаємо незмінений об'єкт
        })
      })
    )
    setTable(newTable);
    setIsModalOpen(false);
  }

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
      >
        <AddForm 
          deseaseData={deseaseData} 
          addItem={addItem} 
          saveItem={saveItem}
        />
      </Modal>
      <Modal
        isOpen={isListOpen}
        onClose={handleCloseList}
      >
          <div className="p-5">
            {data.selected.map((item,key)=>
              <div key={key}>
                <div className="d-flex gap-2">
                  <div onClick={()=>{
                      openEdit(item);
                      setIsListOpen(false);
                    }
                  }><b>{item.route}</b></div>
                  <div>{item.status}</div>
                  <div>{item.cdis}</div>
                  <div>{item.creg}</div>
                  <div>{item.sta}</div>
                  <div>{item.ra}</div>
                  <div>{item.rb}</div>
                  <div>{item.rc}</div>
                  <div className="cursor" onClick={()=>delItem(key)}>del</div>
                </div>
              </div>
            )}
          </div>
      </Modal>
      <Desease
        data={data}
        handleOpenModal={handleOpenModal}
        handleOpenList={handleOpenList}
        categoryId={categoryId}
        sapp={sapp}
      />
    </>
  );
};

export { Line };
