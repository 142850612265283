import { useTable } from "../hook/useTable";

const Desease = ({
  data,
  categoryId,
  sapp,
  handleOpenModal,
  handleOpenList
}) => {

  const {changeSapp,table,setTable} = useTable();

  const change = (e) => {
    const {name,checked} = e.target;

    let updatedData = table;

    if(name == 'check' & !checked){
      updatedData = table.map(item => {
        if (item.id === categoryId) {
          return {
            ...item,
            sapp:null
          }
        }
        return item;
      })
    }

    updatedData = updatedData.map(item => {
      return {
        ...item,
        desease: item.desease.map(disease => {
          if (disease.id === data.id) {
            return {
              ...disease,
              [name]: !disease[name]
            };
          }
          return disease;
        })
      };
    });
    
    setTable(updatedData);
  };

  if(data.id == 1){
    console.log(data.id,sapp);
  }

  return (
    <tr>
      {/* <td className="table-sticky-h"></td> */}
      <td className="table-sticky-h table-second">
        <div className="d-flex justify-content-between align-items-center">
          <div>{data.title}</div>
          <div className={data.check ? "table-line-check" : ""}></div>
        </div>
      </td>
      <td className="text-center">
        <input
          name="check"
          type="checkbox"
          className="checkbox"
          checked={data.check}
          onChange={change}
        />
      </td>
      {/* <td>
        <div
          className={`d-flex flex-wrap gap-2 align-items-center ${
            !data.check ? "disabled" : ""
          }`}
        >
          <div
            className="table-desease-plus"
            onClick={() => handleOpenModal()}
          >Add</div>
          {data.selected.length ? <div className="table-desease d-flex align-items-center flex-nowrap" onClick={()=>handleOpenList()}>Show list of {data.selected.length} choise</div>:"..."}
        </div>
      </td> */}
      <td className="text-center">
        <input
          name="sapp"
          type="checkbox"
          className="checkbox"
          checked={data.id == sapp}
          disabled={!data.check}
          onChange={()=>changeSapp(categoryId,data.id)}
        />
      </td>
      <td className="text-center">
        <input
          name="lbook"
          type="checkbox"
          className="checkbox"
          checked={data.lbook}
          disabled={!data.check}
          onChange={change}
        />
      </td>
      {/*<td className="text-center">
        <input
          name="provide"
          type="checkbox"
          className="checkbox"
          checked={data.provide}
          disabled={!data.check}
          onChange={change}
        />
      </td>
      <td className="text-center">
        <input
          name="trust"
          type="checkbox"
          className="checkbox"
          checked={data.trust}
          disabled={!data.check}
          onChange={change}
        />
      </td>
      <td className="text-center">
        <input
          name="ra"
          type="checkbox"
          className="checkbox"
          checked={data.ra}
          disabled={!data.check}
          onChange={change}
        />
      </td>
      <td className="text-center">
        <input
          name="rb"
          type="checkbox"
          className="checkbox"
          checked={data.rb}
          disabled={!data.check}
          onChange={change}
        />
      </td>
      <td className="text-center">
        <input
          name="rc"
          type="checkbox"
          className="checkbox"
          checked={data.rc}
          disabled={!data.check}
          onChange={change}
        />
      </td>*/}
    </tr>
  );
};

export { Desease };
