import { OverlayTrigger, Tooltip  } from "react-bootstrap";
import CountrySelector from "./CountrySelector";
import SelectInput from "./SelectInput";

import { useMemo, useState } from "react";

const AddForm = ({deseaseData,saveItem,addItem}) => {

    const status = useMemo(() => [
        { "value": 0, "label": "pharm product" },
        { "value": 1, "label": "medical device" },
        { "value": 2, "label": "cosmeceuticals" }
    ], []);

    const route = useMemo(() => [
        { "value": 0, "label": "intramuscular" },
        { "value": 1, "label": "subcutaneous" },
        { "value": 2, "label": "topical product (plasters)" },
        { "value": 3, "label": "topical hydrogel" },
        { "value": 4, "label": "vaginal" },
        { "value": 5, "label": "rectal" },
        { "value": 6, "label": "oral" }
    ], []);

    const [data,setData] = useState(
        deseaseData
        ?deseaseData
        :{
            index: null,
            route: null,
            status: null,
            cdis: null,
            creg: null,
            sta:0,
            ra:false,
            rc:false,
            ra:false,
        }
    );

    const handleSave = () =>{
        const { index, ...dataInfo } = data;
        saveItem(index,dataInfo);
        setData(null);
    }

    const handleAdd = () =>{
        addItem(data);
        setData(null);
    }

    return (
        <>
            <h4 className="mb-3">Add diseases</h4>
            <div className="d-flex gap-4">
                <div className="d-flex flex-column gap-2">
                    <div>
                        <label>Route of administration</label>
                        <SelectInput placeholder="selected option ..." data={data.route} setData={({label})=>setData({...data,route:label})} options={route}/>
                        {/* <input className="inputs w-100" placeholder="Intramuscular"/> */}
                    </div>
                    <div>
                        <label>Registration status</label>
                        <SelectInput placeholder="selected status ..." data={data.status} setData={({label})=>setData({...data,status:label})} options={status}/>
                        {/* <input className="inputs w-100" placeholder="Intramuscular"/> */}
                    </div>
                    <div className="position-relative">
                        <label className="me-2">Country(ies) of distribution of exclusivity</label>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Country(ies) of distribution of exclusivity, commercialisation by disease and route of administration (patent protection of the Licensee's declared countries)</Tooltip>}
                            >
                            <img src="/img/help.svg" height={16}/>
                        </OverlayTrigger>
                        <CountrySelector placeholder="select country ..." data={data.cdis} setData={({label})=>setData({...data,cdis:label})}/>
                        {/* <input className="inputs w-100" placeholder="pharm product"/> */}
                    </div>
                    <div>
                        <label className="me-2">Country(ies) of drug registration</label>
                        <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip>Country(ies) of drug registration, commercialisation by disease and route of administration (patent protection of the Licensee's declared countries)</Tooltip>}
                            >
                            <img src="/img/help.svg" height={16}/>
                        </OverlayTrigger>
                        <CountrySelector placeholder="select country ..." data={data.creg} setData={({label})=>setData({...data,creg:label})}/>
                    </div>
                </div>

                <div className="d-flex flex-column gap-2 p-4">
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <input
                                name="sta"
                                type="radio"
                                className="checkbox"
                                checked={data.sta == 0}
                                onChange={()=>setData({...data,sta:!data.sta})}
                            />
                        </div>
                        <div>
                            <label className="me-2">Provide the dossiers required</label>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>Provide the dossiers required from us to the Customer for the regulatory authority and the CRO</Tooltip>}
                                >
                                <img src="/img/help.svg" height={16}/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <input
                                name="sta"
                                type="radio"
                                className="checkbox"
                                checked={data.sta == 1}
                                onChange={()=>setData({...data,sta:!data.sta})}
                            />
                        </div>
                        <div>
                            <label className="me-2">Carry out a turnkey project</label>
                            <OverlayTrigger
                                placement="top"
                                overlay={<Tooltip>The Customer entrusts us to carry out a turnkey project: from obtaining regulatory approvals for all phases to registering the drug in the customer's name</Tooltip>}
                                >
                                <img src="/img/help.svg" height={16}/>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2 mt-3">
                        <div>
                            <input
                                name="ra"
                                type="checkbox"
                                className="checkbox"
                                checked={data.ra}
                                onChange={()=>setData({...data,ra:!data.ra})}
                            />
                        </div>
                        <div>
                            <label className="me-2">A) Supply for clinical trials by the Licensor</label>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <input
                                name="rb"
                                type="checkbox"
                                className="checkbox"
                                checked={data.rb}
                                onChange={()=>setData({...data,rb:!data.rb})}
                            />
                        </div>
                        <div>
                            <label className="me-2">B) Commercial sales for the Licensee</label>
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2">
                        <div>
                            <input
                                name="rc"
                                type="checkbox"
                                className="checkbox"
                                checked={data.rc}
                                onChange={()=>setData({...data,rc:!data.rc})}
                            />
                        </div>
                        <div>
                            <label className="me-2">С) Commercial sales by the Licensor and the Licensee</label>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center mt-4 gap-2">
                {
                    deseaseData
                    ?<div className="button" onClick={()=>handleSave()}>SAVE</div>
                    :<div className="button" onClick={()=>handleAdd()}>ADD SELECTED</div>
                }
            </div>
        </>
    )
}

export {AddForm};