import { useEffect, useState } from "react";
import data from "./data.json";
import { Category } from "./components/Category";
import { SendForm } from "./components/SendForm";
import { useTable } from "./hook/useTable";

const route = [
 'intramuscular',
 'subcutaneous',
 'topical product (plasters)',
 'topical hydrogel',
 'vaginal',
 'rectal',
 'oral'
];

const status = [
  'pharm product',
  'medical device',
  'cosmeceuticals'
]

function App() {

  // const [table,setTable] = useState(
  //     localStorage.getItem('data')
  //     ?JSON.parse(localStorage.getItem('data'))
  //     :data
  // );

  const {table,setTable} = useTable();

  useEffect(() => {
    const stickyElementsH = document.querySelectorAll('.table-sticky-h');
    const stickyElements = document.querySelectorAll('.table-sticky');

    stickyElementsH.forEach((el) => {
      const rect = el.getBoundingClientRect();
      const style = getComputedStyle(el);
      const borderLeftWidth = parseFloat(style.borderLeftWidth);
      el.style.left = `${rect.left-borderLeftWidth}px`;
    });
    stickyElements.forEach((el) => {
      const rect = el.getBoundingClientRect();
      const style = getComputedStyle(el);
      const borderTopWidth = parseFloat(style.borderTopWidth);
      el.style.top = `${rect.top-borderTopWidth}px`;
    });
  }, []);

  // const change = (categoryId,deseaseId,updatedField) => {
  //   setTable(prevCategories =>
  //       prevCategories.map(category => {
  //         if (category.id === categoryId) {
  //           return {
  //             ...category,
  //             desease: category.desease.map(desease => {
  //               if (desease.id === deseaseId) {
  //                 return {
  //                   ...desease,
  //                   ...updatedField
  //                 };
  //               }
  //               return desease;
  //             })
  //           };
  //         }
  //         return category;
  //       })
  //     );
  //     // const { name, checked } = e.target;
  //     // setTable(data.id, { [name]: checked });
  // };

  useEffect(() => {
    localStorage.setItem('data', JSON.stringify(table));
  }, [table]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div className="App">
        <SendForm isOpen={isModalOpen} onClose={()=>setIsModalOpen(false)}/>
        <div className="d-flex flex-column h-100">
          <div className="table-form-block flex-grow-1 overflow">
            <table className="table-form" style={{minWidth:"100%"}}>
              <thead>
                <tr>
                  {/*<th width={100} className="table-sticky-h table-sticky table-sticky-both" rowSpan="2">WHO</th>*/}
                  <th style={{width:"200px",minWidth:"200px"}} className="table-sticky-h table-sticky table-sticky-both" rowSpan="2">Disease groups and sub-groups</th>
                  <th style={{width:"100px",minWidth:"100px"}} rowSpan="2" className="table-sticky">Disease selection</th>
                  {/* <th style={{minWidth:"280px"}} rowSpan="2" className="table-sticky">Selected diseases</th> */}
                  <th colSpan="2" className="table-sticky"><small>Starting disease application for CTA and registration</small></th>
                  {/* <th colSpan="2" className="table-sticky">CTA</th>
                  <th colSpan="3" className="table-sticky">Release of the drug for all phases of clinical trials by the Licensor</th> */}
                </tr>
                <tr>
                  <th style={{width:"200px",minWidth:"200px"}} className="table-sticky">Starting disease application for CTA and registration</th>
                  <th style={{width:"200px",minWidth:"200px"}} className="table-sticky">Licence booking of diseases for additional agreement by the ‘Parties’</th>
                  {/* <th width={200} className="table-sticky"><small>Provide the dossiers required from us to the Customer for the regulatory authority and the CRO</small></th>
                  <th width={300} className="table-sticky"><small>The Customer entrusts us to carry out a turnkey project: from obtaining regulatory approvals for all phases to registering the drug in the customer's name</small></th>
                  <th className="table-sticky">A) Supply for clinical trials by the Licensor</th>
                  <th className="table-sticky">B) Commercial sales for the Licensee</th>
                  <th className="table-sticky">С) Commercial sales by the Licensor and the Licensee</th> */}
                </tr>
              </thead>
                {table.map((item, index) => (
                  <Category key={index} categoryId={item.id} data={item}/>
                ))}
            </table>
          </div>
          <div className="d-flex p-3 py-2 justify-content-between footer align-items-center gap-5">
            <div></div>
            {/* <div className="footer-title">2 Selected diseases</div> */}
            <button className="button" onClick={()=>setIsModalOpen(true)}>Send form</button>
          </div>
        </div>
    </div>
  );
}

export default App;
