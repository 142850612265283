import {createContext,useState} from "react";

import data from "../data.json";

export const TableContext = createContext(null);

export const TableProvider = ({children}) => {

    // const [table,setTable] = useState(
    //     localStorage.getItem('data')
    //     ?JSON.parse(localStorage.getItem('data'))
    //     :data
    // );

    const [table,setTable] = useState(data);

    const changeSapp = (id,idDisease) => {
        const newData = table.map(item => 
            item.id === id ? { ...item,sapp:idDisease} : item
        )
        console.log(newData);
        setTable(newData);
    }

    const value = {
        table,
        setTable,
        changeSapp
    }

    return <TableContext.Provider value={value}>
        {children}
    </TableContext.Provider>
}